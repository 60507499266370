import { Github } from '@styled-icons/boxicons-logos/Github';
import { Twitter } from '@styled-icons/boxicons-logos/Twitter';
import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin';

const Icons = {
  Github,
  Twitter,
  Linkedin,
}

export default Icons;
