const links = [
  {
    label: "Github",
    url: "https://github.com/naaataliaazevedo",
  },
  {
    label: "Twitter",
    url: "https://twitter.com/naaataliaazevedo",
  },
  {
    label: "Linkedin",
    url: "https://www.linkedin.com/in/nat%C3%A1lia-de-azevedo-90278b56",
  },
]

export default links;
